.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Ensure it's above other content */
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
  
  /* Additional styling for form inputs */
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .up{
    color: blue;
    cursor: pointer;
  }
  .up:hover{
    text-decoration: underline;
  }
.select-container{
    width: 90%;
    padding: 10px;
    border-radius: 4px;
    margin: 10px;
    font-size: 15px;
  }